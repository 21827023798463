import React from "react"
import { graphql, Link, navigate } from "gatsby"
import { login, isAuthenticated, getProfile } from "../utils/auth"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'


import Layout from "../components/layout"
import Head from "../components/head"

import blogStyles from "./blog.module.scss"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        tag
      }
      html
    }
  }
`

const Blog = ({ data, pageContext }) => {
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }
  const user = getProfile()
  const classes = user["https://www.tigers-club.com/memberships"]

  const post = data.markdownRemark

  if (post.frontmatter.tag !== "all") {

    if (!classes.includes(post.frontmatter.tag)) {
      navigate("/dashboard/class-updates")
    }
  }

  // const prevPost = pageContext.prev ? {
  //   url: `/dashboard/blog/${pageContext.next.frontmatter.tag}/${pageContext.prev.fields.slug}`,
  //   title: pageContext.prev.frontmatter.title
  // }
  //   : null
  // const nextPost = pageContext.next ? {
  //   url: `/dashboard/blog/${pageContext.next.frontmatter.tag}/${pageContext.next.fields.slug}`,
  //   title: pageContext.next.frontmatter.title
  // }
  //   : null

  return (
    <Layout>
      <div className={blogStyles.container}>

        <nav className={blogStyles.nav}>
          <ul>
            <li><Link className={blogStyles.link} to={`/dashboard/${post.frontmatter.tag === "all" ? "school-updates" : "class-updates"}/`}>
              <FontAwesomeIcon icon={faHome} /> Dashboard
            </Link></li>

            {/* {prevPost && (
              <li><Link to={prevPost.url}>
                <span>Previous</span>
                <h3>{prevPost.title}</h3>
              </Link></li>
            )}

            {nextPost && (
              <li><Link to={nextPost.url}>
                <span>Next</span>
                <h3>{nextPost.title}</h3>
              </Link></li>
            )} */}

          </ul>
        </nav>

        <Head title={post.frontmatter.title} />
        <h1 className={blogStyles.title}>{post.frontmatter.title}</h1>
        <p className={blogStyles.date}>{post.frontmatter.date}</p>

        <div className={blogStyles.post} dangerouslySetInnerHTML={{ __html: post.html }} />

      </div>
    </Layout>
  )
}

export default Blog
